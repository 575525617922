<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="titmsg">填写并核对订单信息</div>
        <el-col :span="24">
          <div class="titBox">
            <TitleHeader msg="收货信息"></TitleHeader>
          </div>
          <DeliveryInfo v-if="isReady" :goodsList="orderList"></DeliveryInfo>
        </el-col>
        <el-col :span="12">
           <div class="titBox">
          <TitleHeader msg="发票信息"></TitleHeader>
           </div>
          <div class="invoiceItem">
            <p>
              <span class="TitLabel">开票方式:</span>
              <span>{{ invoiceType }}</span>
            </p>
            <p>
              <span class="TitLabel">发票类型:</span>
              <span>{{ invoiceManner }}</span>
            </p>
          </div>
        </el-col>
        <el-col :span="12">
           <div class="titBox">
          <TitleHeader msg="支付方式"></TitleHeader>
           </div>
          <div class="paymentType">
            <div class="paymentLine">
              <span class="TitLabel">支付方式:</span>
              <span class="payBox">
                在线支付
              </span>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
            <div class="notitBox">
          <TitleHeader msg="商品清单" :noBorder="true"></TitleHeader>
            </div>
          <CommodityList
            v-if="isReady"
            :goodsList="orderList"
            @OrderFun="amountOrderFun"
            @ItemFun="amountItemFun"
          ></CommodityList>
        </el-col>
      </el-col>
    </el-row>
    <DiscountFree v-if="isReady" :goodsList="orderList"></DiscountFree>
    <AmountBottom v-if="isReady" :goodsList="orderList"></AmountBottom>
  </div>
</template>

<script>
const TitleHeader = () => import("components/common/TitleHeader.vue");
const DeliveryInfo = () => import("components/order/DeliveryInfo.vue");
const CommodityList = () => import("components/order/CommodityList.vue");
const DiscountFree = () => import("components/order/DiscountFree.vue");
const AmountBottom = () => import("components/order/AmountBottom.vue");
// import { CartList } from "api/product.js"  , relativeCoupon;
import { carToOrder } from "api/carToOrder.js";
// import {AgainOrder} from "api/order.js";
// import {mapState} from "vuex";
import { PromotionOrder } from "api/activity.js";
import {freightList} from "api/order.js";
export default {
  name: "GoodsAmount",
  data() {
    return {
      isReady:false,
      moreIds: "", //购物车商品ids
      orderList: "",
      invoiceManner: "", //发票类型
      invoiceType: "", //开票方式
      PayOptions: [
        {
          value: "15",
          label: "顺丰快递15元",
        },
        {
          value: "10",
          label: "京东物流10元",
        },
        {
          value: "5",
          label: "菜鸟快递5元",
        },
      ],
      value: "",
    };
  },
  //  computed: {
  //   ...mapState({
  //     goods: (state) => state.goods,
  //   }),
  //  },
  components: {
    TitleHeader,
    DeliveryInfo,
    CommodityList,
    DiscountFree,
    AmountBottom,
  },
  methods: {
    // 单个商品勾选优惠券 传值
    amountItemFun(data){
          let i=Number(data.soleId)
          this.orderList.orderDetails[i].couponId=data.couponId
          this.orderList.orderDetails[i].couponAmount=data.amount
          let couponAmount=0;
          for(var m=0 ;m<this.orderList.orderDetails.length;m++){
            if(Number(this.orderList.orderDetails[m].couponAmount)>0){
              couponAmount+=Number(this.orderList.orderDetails[m].couponAmount)
            }
          }
          this.orderList.couponDetailAmount=couponAmount.toFixed(2)
      this.payableAmountFun()
    },

    //订单优惠券勾选  传值
    amountOrderFun(data){
      if(data==''){
          this.orderList.couponAmount=0
          this.orderList.couponId=''
      }else{
       
          this.orderList.couponAmount=data.amount
          this.orderList.couponId=data.couponId
      }
       this.payableAmountFun()
    },

    payableAmountFun(){
       let  couponAmountNum=0;    //订单优惠券
      if(this.orderList.couponAmount>0){
          couponAmountNum=Number(this.orderList.couponAmount)
      }
      let originalAmount=Number(this.orderList.originalAmount)    //商品金额

      let couponDetailAmount=Number(this.orderList.couponDetailAmount) //单品优惠券
      let promotionAmount=Number(this.orderList.promotionAmount)   //促销折扣

      // 除运费之后的价格
      let  totalPrice=originalAmount-couponDetailAmount-promotionAmount-couponAmountNum
      if(totalPrice<0){
        totalPrice= Number(this.orderList.goodsCount) * 0.01
      }
      let freight=Number(this.orderList.freight)     //运费

      // alert(this.orderList.orderType!=4 && this.orderList.orderType!=5)
      if(totalPrice>0){

        // 如果非a+b活动订单  请求接口查运费区间，根据运费区间得到运费价格
        if(this.orderList.orderType!=4 && this.orderList.orderType!=5){
          freightList().then(res=>{
              let records=res.data.data.records;
              records.forEach(item => {
                if(totalPrice>item.startMoney && totalPrice<item.endMoney){
                    freight=Number(item.freight)     //运费
                    // 应付金额
                    let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum
                    this.orderList.payableAmount=payableAmount.toFixed(2);
                    this.orderList.freight=Number(item.freight)
                    //  alert('aaaaa'+ this.orderList.freight)
                    return false
                }
              });
            })
        }else{
          let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum
          this.orderList.payableAmount=payableAmount.toFixed(2);
          this.orderList.freight=Number(freight) 
        }
      }
      // let freight=Number(this.orderList.freight)     //运费
    
     
    },

    // 通过data渲染数据
    RenderData(data){
          this.orderList = data.data.data;
          this.isReady=true;

          //发票信息
          switch (this.orderList.memberStore.invoiceType) {
            case 1:
              this.invoiceType = "纸质发票";
              break;
            case 2:
              this.invoiceType = "电子发票";
              break;

            default:
              this.invoiceType = "无";
              break;
          }
          switch (this.orderList.memberStore.invoiceManner) {
            case 1:
              this.invoiceManner = "普票";
              break;
            case 2:
              this.invoiceManner = "增值税专用发票";
              break;
            default:
              this.invoiceManner = "无";
              break;
          }
          // 关闭loading
          this.$loading().close();
    }


  },
  created() {
     // 显示loading
      this.$loading({ fullscreen: true ,background:'#ffffff45'})
      if(this.$route.query.id=="combo"){
        let datas=JSON.parse(sessionStorage.getItem("comBo"))
            console.log(datas)
        PromotionOrder(datas).then(data=>{
          if(data.data.code==400){
                this.$message({
                  message: data.data.msg,
                  type: "warning",
                });
               window.history.back()
            }
           // 通过data渲染数据
            this.RenderData(data)
          // aaaaaaaaaa--------------zhushi-----------------
          //  let CarData=data
          //  // 获取购物车加入订单  优惠券  start
          //  let CouponMoreIds = "";
          //  let items=data.data.data.orderDetails;
          //   for (var i = 0; i < items.length; i++) {
          //     let CouponId = items[i].goodsId;
          //     CouponMoreIds += CouponId + ",";
          //   }
          //      // 获取购物车加入订单  优惠券
          //   relativeCoupon(CouponMoreIds).then((data) => {
              
          //     if(data.data.data.length===0){
          //           // 通过data渲染数据
          //         this.RenderData(CarData)
          //     }else{
          //         carToOrder(ids).then((data) => {
          //           // 通过data渲染数据
          //           this.RenderData(data)
          //         })
          //     }
          //   });
            // 获取购物车加入订单  优惠券  end

            // aaaaaaaaaa--------------zhushi-----------------
        })
        return false
      }
       else if(this.$route.query.id=="FlashGroup"){
        let datas=JSON.parse(sessionStorage.getItem("FlashGroup"))
            console.log(datas)
        PromotionOrder(datas).then(data=>{
          if(data.data.code==400){
                this.$message({
                  message: data.data.msg,
                  type: "warning",
                });
               window.history.back()
            }
           // 通过data渲染数据
            this.RenderData(data)
        
        })
        return false
      }

    // if(this.$route.query.id){
    //   // 从再来一单进入订单结算
    //   let orderIds={
    //     id:this.$route.query.id
    //   }
    //   AgainOrder(orderIds).then((data)=>{
    //      if(data.data.code==400){
    //             this.$message({
    //               message: data.data.msg,
    //               type: "warning",
    //             });
    //            this.$router.push({ path: "/cart" });
    //       }
    //     // 通过data渲染数据
    //      this.RenderData(data)
    //     // 获取购物车加入订单  优惠券  start
    //     let CouponMoreIds = "";
    //     let items=data.data.data.orderDetails;
    //     for (var i = 0; i < items.length; i++) {
    //       let CouponId = items[i].goodsId;
    //       CouponMoreIds += CouponId + ",";
    //     }
    //     // 获取购物车加入订单  优惠券
    //     relativeCoupon(CouponMoreIds).then(() => {
    //     });
    //      // 获取购物车加入订单  优惠券  end
    //   })
    // }else{
        let moreIds=this.$route.query.ids
        let cancelGift=this.$route.query.cancelGift
        let notBuyNum=this.$route.query.notBuyCount
        //获取所有商品的ids
        this.moreIds = moreIds;
        let ids = { ids: this.moreIds ,cancelGift:cancelGift,notBuyNum:notBuyNum};
        // 获取购物车加入订单接口
        carToOrder(ids).then((data) => {
              if(data.data.code==400){
                    this.$message({
                      message: data.data.msg,
                      type: "warning",
                    });
                    window.history.back()
                  //  this.$router.push({ path: "/cart" });
              }else{
                    // 通过data渲染数据
                    this.RenderData(data)
              }
                
           // aaaaaaaaaa--------------zhushi-----------------
          // let CarData=data
          //  // 获取购物车加入订单  优惠券  start
          //  let CouponMoreIds = "";
          //  let items=data.data.data.orderDetails;
          //   for (var i = 0; i < items.length; i++) {
          //     let CouponId = items[i].goodsId;
          //     CouponMoreIds += CouponId + ",";
          //   }
          //      // 获取购物车加入订单  优惠券
          //   relativeCoupon(CouponMoreIds).then((data) => {
          //     if(data.data.data.length===0){
          //           // 通过data渲染数据
          //         this.RenderData(CarData)
          //     }else{
          //         carToOrder(ids).then((data) => {
          //           // 通过data渲染数据
          //           this.RenderData(data)
          //         })
          //     }
               
          //   });
            // 获取购物车加入订单  优惠券  end

             // aaaaaaaaaa--------------zhushi-----------------
        });
    // }
  },
};
</script>


<style lang="less" scoped>
@import "~style/index.less";
.titBox{
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}
.notitBox{
   padding: 20px;
}
.titmsg {
  font-size: 16px;
  color: #333;
  line-height: 62px;
  font-weight: bold;
}
.invoiceItem {
  padding: 0 20px 20px 20px;
  border: 1px solid #f0f0f0;
  border-top: none;
  p {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .TitLabel {
      padding-right: 10px;
    }
    
  }
}
.paymentType {
  padding: 0 20px 36px 20px;
  border: 1px solid #f0f0f0;
  border-top: none;
  .paymentLine {
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .TitLabel {
      padding-right: 10px;
    }
    .payBox{
        padding:6px 18px;
        width:66px;
        height:20px;
        display: inline-block;
         text-align: center;
        line-height:20px;
        border: 2px solid #FFD303;
        background: url("../assets/checkedRed.png") no-repeat right bottom;
        background-size: 26px 26px;
    }
  }
}
</style>
